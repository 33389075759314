exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biegniepodleglosci-index-jsx": () => import("./../../../src/pages/biegniepodleglosci/index.jsx" /* webpackChunkName: "component---src-pages-biegniepodleglosci-index-jsx" */),
  "component---src-pages-biegniepodleglosci-kontakt-jsx": () => import("./../../../src/pages/biegniepodleglosci/kontakt.jsx" /* webpackChunkName: "component---src-pages-biegniepodleglosci-kontakt-jsx" */),
  "component---src-pages-biegniepodleglosci-lista-startowa-jsx": () => import("./../../../src/pages/biegniepodleglosci/lista-startowa.jsx" /* webpackChunkName: "component---src-pages-biegniepodleglosci-lista-startowa-jsx" */),
  "component---src-pages-biegniepodleglosci-regulamin-jsx": () => import("./../../../src/pages/biegniepodleglosci/regulamin.jsx" /* webpackChunkName: "component---src-pages-biegniepodleglosci-regulamin-jsx" */),
  "component---src-pages-biegniepodleglosci-wyniki-jsx": () => import("./../../../src/pages/biegniepodleglosci/wyniki.jsx" /* webpackChunkName: "component---src-pages-biegniepodleglosci-wyniki-jsx" */),
  "component---src-pages-biegniepodleglosci-zapisy-jsx": () => import("./../../../src/pages/biegniepodleglosci/zapisy.jsx" /* webpackChunkName: "component---src-pages-biegniepodleglosci-zapisy-jsx" */),
  "component---src-pages-biegtransgraniczny-index-jsx": () => import("./../../../src/pages/biegtransgraniczny/index.jsx" /* webpackChunkName: "component---src-pages-biegtransgraniczny-index-jsx" */),
  "component---src-pages-biegtransgraniczny-kontakt-jsx": () => import("./../../../src/pages/biegtransgraniczny/kontakt.jsx" /* webpackChunkName: "component---src-pages-biegtransgraniczny-kontakt-jsx" */),
  "component---src-pages-biegtransgraniczny-lista-startowa-jsx": () => import("./../../../src/pages/biegtransgraniczny/lista-startowa.jsx" /* webpackChunkName: "component---src-pages-biegtransgraniczny-lista-startowa-jsx" */),
  "component---src-pages-biegtransgraniczny-regulamin-jsx": () => import("./../../../src/pages/biegtransgraniczny/regulamin.jsx" /* webpackChunkName: "component---src-pages-biegtransgraniczny-regulamin-jsx" */),
  "component---src-pages-biegtransgraniczny-wyniki-jsx": () => import("./../../../src/pages/biegtransgraniczny/wyniki.jsx" /* webpackChunkName: "component---src-pages-biegtransgraniczny-wyniki-jsx" */),
  "component---src-pages-biegtransgraniczny-zapisy-jsx": () => import("./../../../src/pages/biegtransgraniczny/zapisy.jsx" /* webpackChunkName: "component---src-pages-biegtransgraniczny-zapisy-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

